<template>
  <div class="container">
    <h1>Terms of Service</h1>
    <h2>Terms of Service for Alexander's Rentals</h2>
    <p>Last Updated: 02/03/2024</p>
    <h3>1. Acceptance of Terms</h3>
    <p>By accessing and using the website of Alexander's Rentals ('the Website'), you agree to be bound by these Terms of Service ('Terms') and all applicable laws and regulations. If you disagree with any part of these Terms, you are prohibited from using or accessing this Website.</p>
    <h3>2. Use License</h3>
    <ul>
      <li>a. Subject to your compliance with these Terms, Alexander's Rentals grants you a non-exclusive, non-transferable, limited license to access and use the Website solely for searching for rental listings and contacting agents for property showings.</li>
      <li>b. This license shall automatically terminate if you violate any of these restrictions and may be terminated by Alexander's Rentals at any time.</li>
    </ul>
    <h3>3. Prohibited Uses</h3>
    <p>You agree not to use the Website:</p>
    <ul>
      <li>a. In any way that breaches any applicable local, national, or international law or regulation.</li>
      <li>b. For any unauthorized or fraudulent purpose or activity.</li>
      <li>c. To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material.</li>
    </ul>
    <h3>4. Intellectual Property</h3>
    <p>The content, features, and functionality of the Website are and will remain the exclusive property of Alexander's Rentals and its licensors. Unauthorized use of the Website may give rise to a claim for damages and/or be a criminal offense.</p>
    <h3>5. Disclaimer</h3>
    <p>The Website is provided on an 'AS IS' and 'AS AVAILABLE' basis. Alexander's Rentals makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the Website or the information, products, services, or related graphics contained on the Website for any purpose.</p>
    <h3>6. Limitation of Liability</h3>
    <p>In no event shall Alexander's Rentals, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Website.</p>
    <h3>7. Indemnification</h3>
    <p>You agree to defend, indemnify, and hold harmless Alexander's Rentals and its licensee and licensors, and their employees, contractors, agents, officers, and directors from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses.</p>
    <h3>8. Modifications to Terms</h3>
    <p>Alexander's Rentals reserves the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Website after revisions become effective, you agree to be bound by the revised terms.</p>
    <h3>9. Governing Law</h3>
    <p>These Terms shall be governed by and construed in accordance with the laws of Massachusetts, United States, without regard to its conflict of law provisions.</p>
    <h3>10. Contact Us</h3>
    <p>If you have any questions about these Terms, please contact us at [Insert Contact Information].</p>
  </div>
</template>

<script>
export default {
  name: "TermsOfServicePage",
};
</script>

<style scoped>
  h1 {
    margin-bottom: 5vh;
    text-decoration: underline;
  }
  p {
    margin-bottom: 3vh;
  }
  h2, h3 {
    margin-bottom: 2vh;
  }
  ul {
    margin-bottom: 2vh;
  }
  .container {
    border: 3px solid black;
    padding: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
</style>
