<!-- SearchFilters.vue  -->
<template>
  <div class="search-container">
    <!-- <button class="reset-btn" @click="resetFilters">Reset Filters</button> -->
    <!-- Search Filters -->
    <div class="filters">
        <div class="info-group location-input">
          <div class="location-input-group inner-group"> <!-- v-show="openGroups.locationInput" -->
            <input class="value" type="text" v-model="localSearchCriteria.street_name" placeholder="Street Name..." @input="validateStreetName" />
            <p class="error-message" v-if="streetNameError">{{ streetNameError }}</p>
            <input class="value" type="text" v-model="localSearchCriteria.zip" placeholder="Zip Code..." @input="validateZipCode" />
            <p class="error-message" v-if="zipCodeError">{{ zipCodeError }}</p>
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.city_neighborhood"
                :options="allNeighborhoods"
                :multiple="true"
                :close-on-select="true"
                placeholder="City/Neighborhood..."
                label="name"
                track-by="name"
                @update:city_neighborhood="updateCityNeighborhood"
              />
          </div>
          </div>
        </div>
        <div class="info-group bed-bath-sqft">
          <div class="bedrooms-bathrooms-group inner-group">
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.beds"
                :options="bedMapping"
                :multiple="true"
                :close-on-select="true"
                placeholder="Number of Beds..."
                label="name"
                track-by="name"
                @update:beds="updateBeds"
              />
            </div>
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.baths"
                :options="bathMapping"
                :multiple="true"
                :close-on-select="true"
                placeholder="Number of Baths..."
                label="name"
                track-by="name"
                @update:baths="updateBaths"
              />
            </div>
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.laundry"
                :options="laundryMapping"
                :multiple="true"
                :close-on-select="true"
                placeholder="Laundry..."
                label="name"
                track-by="name"
                @update:laundry="updateLaundry"
              />
            </div>
          </div>
        </div>
        <div class="info-group min-max-rent">
          <div class="min-max-rent-group inner-group"> <!-- v-show="openGroups.minMaxRent" -->
            <input class="value" type="text" v-model="localSearchCriteria.min_rent" placeholder="Min Rent..." @input="validateMinRent"/>
            <p class="error-message" v-if="minRentError">{{ minRentError }}</p>
            <input class="value" type="text" v-model="localSearchCriteria.max_rent" placeholder="Max Rent..." @input="validateMaxRent"/>
            <p class="error-message" v-if="maxRentError">{{ maxRentError }}</p>        
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.listing_fee"
                :options="feeMapping"
                :multiple="true"
                :close-on-select="true"
                placeholder="Fee..."
                label="name"
                track-by="name"
                @update:listing_fee="updateFee"
              />
            </div>
          </div>
        </div>
        <div class="info-group laundry-parking-pet">
          <div class="laundry-parking-pet-group inner-group"> <!-- v-show="openGroups.laundryParkingPet" -->
            <input class="value" type="text" v-model="localSearchCriteria.square_footage_min" placeholder="Minimum Square Feet..." @input="validateMinSqft"/>
            <p class="error-message" v-if="minSqftError">{{ minSqftError }}</p>
            <input class="value" type="text" v-model="localSearchCriteria.square_footage_max" placeholder="Maximum Square Feet..." @input="validateMaxSqft"/>
            <p class="error-message" v-if="maxSqftError">{{ maxSqftError }}</p>
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.pet"
                :options="petMapping"
                :multiple="true"
                :close-on-select="true"
                placeholder="Pets..."
                label="name"
                track-by="name"
                @update:pet="updatePet"
              />
            </div>
          </div>
        </div>
        <div class="info-group photo-tour-feature">
          <div class="property-status-media-group inner-group">            
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.parking"
                :options="parkingMapping"
                :multiple="true"
                :close-on-select="true"
                placeholder="Parking..."
                label="name"
                track-by="name"
                @update:parking="updateParking"
              />
            </div>
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.photo"
                :options="photoMapping"
                :multiple="true"
                :close-on-select="true"
                placeholder="Photos..."
                label="name"
                track-by="name"
                @update:photo="updatePhotos"
              />
            </div>
            <div class="multi-container">
              <VueMultiselect
                class="multiselect"
                v-model="localSearchCriteria.tours"
                :options="tourMapping"
                :multiple="true"
                :close-on-select="true"
                placeholder="Tours..."
                label="name"
                track-by="name"
                @update:tours="updateTours"
              />
            </div>
          </div>
        </div>
      <div class="info-group">
        <div class="avail-dates-group inner-group"> <!-- v-show="openGroups.availDates" -->
          <div class="avail-dates">
            <div class="datepicker-container">
              <Datepicker class="datepicker" v-model="localSearchCriteria.avail_from" placeholder="From Date"></Datepicker>
            </div>
            <div class="datepicker-container">
              <Datepicker class="datepicker" v-model="localSearchCriteria.avail_to" placeholder="To Date"></Datepicker>
            </div>
          </div>            
          <div class="multi-container">
            <VueMultiselect
              class="multiselect"
              v-model="localSearchCriteria.features"
              :options="featureMapping"
              :multiple="true"
              :close-on-select="true"
              placeholder="Features..."
              label="name"
              track-by="name"
              @update:features="updateFeatures"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="search-btn-container">
      <button class="search-btn" @click="searchListings">Search</button>
    </div>
  </div>
</template>

<script>
/**
 * This component is responsible for displaying the search filters.
 * It receives the search criteria from the SearchPage component, and displays them in a form.
 * It also allows the user to update the search criteria, and search for listings.
 * 
 * The search criteria is passed down from the SearchPage component, and is updated in this component.
 * When the user clicks the search button, the search criteria is passed back up to the SearchPage component.
 * The SearchPage component then uses the search criteria to search for listings.
 */
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import downArrow from '@/assets/down-arrow.png';
import { laundryMapping, featureMapping, bathMapping, allNeighborhoods, feeMapping, bedMapping, photoMapping, parkingMapping, petMapping, tourMapping } from '../utils/dataSets.js';
import VueMultiselect from 'vue-multiselect';
import '../../node_modules/vue-multiselect/dist/vue-multiselect.css';

export default {
  components: {
      Datepicker,
      VueMultiselect,
  },
  props: {
      searchCriteria: {
          type: Object,
          required: true
      }
  },
  computed: {
    localSearchCriteria: {
        get() {
          return this.searchCriteria;
        },
        set() {
          // Sends the event with updated criteria.
          this.$emit('update-criteria', this.searchCriteria);
        },
    },
    apiFormattedNeighborhoods() {
      if (!this.localSearchCriteria.city_neighborhood.length) {
        return null; // Return null if no selection
      }
      return this.localSearchCriteria.city_neighborhood.map(item => item.apiValue.replace('-', ':'));
    }
  },
  methods: {
    // Input validation methods.
    validateStreetName() {
      const streetNameRegex = /^[a-zA-Z0-9\s.'-]+$/;
      if (streetNameRegex.test(this.localSearchCriteria.street_name) || this.localSearchCriteria.street_name === '') {
        this.streetNameError = null;
      } else if (streetNameRegex && !streetNameRegex.test(this.localSearchCriteria.street_name)) {
        this.streetNameError = 'Please enter a valid street name';
      }
    },
    validateZipCode() {
      const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
      if (zipCodeRegex.test(this.localSearchCriteria.zip) || this.localSearchCriteria.zip === '') {
        this.zipCodeError = null;
      } else if (zipCodeRegex && !zipCodeRegex.test(this.localSearchCriteria.zip)) {
        this.zipCodeError = 'Please enter a valid zip code';
      }
    },
    validateMinRent() {
      const minRentRegex = /^\d+$/;
      if (minRentRegex.test(this.localSearchCriteria.min_rent) || this.localSearchCriteria.min_rent === '') {
        this.minRentError = null;
      } else if (minRentRegex && !minRentRegex.test(this.localSearchCriteria.min_rent)) {
        this.minRentError = 'Please enter a valid minimum rent';
      }
    },
    validateMaxRent() {
      const maxRentRegex = /^\d+$/;
      if (maxRentRegex.test(this.localSearchCriteria.max_rent) || this.localSearchCriteria.max_rent === '') {
        this.maxRentError = null;
      } else if (maxRentRegex && !maxRentRegex.test(this.localSearchCriteria.max_rent)) {
        this.maxRentError = 'Please enter a valid maximum rent';
      }
    },
    validateMinSqft() {
      const minSqftRegex = /^\d+$/;
      if (minSqftRegex.test(this.localSearchCriteria.square_footage_min) || this.localSearchCriteria.square_footage_min === '') {
        this.minSqftError = null;
      } else if (minSqftRegex && !minSqftRegex.test(this.localSearchCriteria.square_footage_min)) {
        this.minSqftError = 'Please enter a valid minimum square footage';
      }
    },
    validateMaxSqft() {
      const maxSqftRegex = /^\d+$/;
      if (maxSqftRegex.test(this.localSearchCriteria.square_footage_max) || this.localSearchCriteria.square_footage_max === '') {
        this.maxSqftError = null;
      } else if (maxSqftRegex && !maxSqftRegex.test(this.localSearchCriteria.square_footage_max)) {
        this.maxSqftError = 'Please enter a valid maximum square footage';
      }
    },
    // Searches for listings using the search criteria.
    searchListings() {
      if (this.localSearchCriteria.max_rent <= 1500 && this.localSearchCriteria.max_rent !== '' && this.localSearchCriteria.max_rent !== null) {
        window.alert('CAUTION: Many listings you see for under $1500 are either single rooms, or parking spots for rent. We will not assist in the pursuit of these listings. Please pay attention to listing details.');
      }
      const criteriaForApi = this.prepareSearchCriteriaForApi();
      // Sends the event to the parent component.
      this.$emit('search', criteriaForApi);
    },
    // Toggles the group with the given name (mobile dropdowns).
    toggleGroup(groupName) {
      this.openGroups[groupName] = !this.openGroups[groupName];
      // Find the button element and update its aria-expanded attribute.
      const button = this.$el.querySelector(`.toggle-btn[data-group="${groupName}"]`);
      if (button) {
        button.setAttribute('aria-expanded', this.openGroups[groupName]);
      }
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date);
      return ((d.getMonth() + 1).toString().padStart(2, '0') + '/' +
              d.getDate().toString().padStart(2, '0') + '/' +
              d.getFullYear());
    },
    // Transforms the search criteria to match the API's format.
    prepareSearchCriteriaForApi() {
      const apiCriteria = { ...this.searchCriteria };
      // Neighborhoods
      if (this.neighborhoodMapping &&apiCriteria.city_neighborhood in this.neighborhoodMapping) {
        apiCriteria.city_neighborhood = this.neighborhoodMapping[apiCriteria.city_neighborhood];
      }
      // Fee
      if (this.feeMapping && apiCriteria.listing_fee in this.feeMapping) {
        apiCriteria.listing_fee = this.feeMapping[apiCriteria.listing_fee];
      }
      // Beds
      if (this.bedMapping && apiCriteria.beds in this.bedMapping) {
        apiCriteria.beds = this.bedMapping[apiCriteria.beds];
      }
      // max_bed
      if (this.bedMapping && apiCriteria.max_bed in this.bedMapping) {
        apiCriteria.max_bed = this.bedMapping[apiCriteria.max_bed];
      }
      // min_bed
      if (this.bedMapping && apiCriteria.min_bed in this.bedMapping) {
        apiCriteria.min_bed = this.bedMapping[apiCriteria.min_bed];
      }
      // Baths
      if (this.bathMapping && apiCriteria.baths in this.bathMapping) {
        apiCriteria.baths = this.bedMapping[apiCriteria.baths];
      }
      // Photos
      if (this.photoMapping && apiCriteria.photo in this.photoMapping) {
        apiCriteria.photo = this.photoMapping[apiCriteria.photo];
      }
      // Parking
      if (this.parkingMapping && apiCriteria.parking in this.parkingMapping) {
        apiCriteria.parking = this.parkingMapping[apiCriteria.parking];
      }
      // Pet
      if (this.petMapping && apiCriteria.pet in this.petMapping) {
        apiCriteria.pet = this.petMapping[apiCriteria.pet];
      }

      if (this.featuresMapping && apiCriteria.features in this.featuresMapping) {
        apiCriteria.features = this.featuresMapping[apiCriteria.features];
      }

      if (this.laundryMapping && apiCriteria.laundry in this.laundryMapping) {
        apiCriteria.laundry = this.laundryMapping[apiCriteria.laundry];
      }

      if (apiCriteria.avail_from) {
        apiCriteria.avail_from = this.formatDate(this.searchCriteria.avail_from);
      }
      if (apiCriteria.avail_to) {
        apiCriteria.avail_to = this.formatDate(this.searchCriteria.avail_to);
      }

      if (Array.isArray(apiCriteria.features)) {
        // Transform the features array into strings separated by commas.
        apiCriteria.features = apiCriteria.features.map(feature => feature.name).join(',')
      }

      // Virtual Tours
      if (apiCriteria.tours === 'Virtual Tours Only') {
        apiCriteria.tours = "Y";
      }

      return apiCriteria;
    },
    // Resets the search criteria to the default values.
    resetFilters() {
      window.location.reload();
    },
    // Helper functions to update localSearchCriteria from 
    // the VueMultiselect component.
    updateFeatures(newFeatures) {
      this.localSearchCriteria.features = newFeatures;
    },
    updateCityNeighborhood(newCityNeighborhood) {
      this.localSearchCriteria.city_neighborhood = newCityNeighborhood;
    },
    updateBeds(newBeds) {
      this.localSearchCriteria.beds = newBeds;
    },
    updateBaths(newBaths) {
      this.localSearchCriteria.baths = newBaths;
    },
    updateLaundry(newLaundry) {
      this.localSearchCriteria.laundry = newLaundry;
    },
    updateFee(newFee) {
      this.localSearchCriteria.listing_fee = newFee;
    },
    updatePet(newPet) {
      this.localSearchCriteria.pet = newPet;
    },
    updateParking(newParking) {
      this.localSearchCriteria.parking = newParking;
    },
    updatePhotos(newPhotos) {
      this.localSearchCriteria.photo = newPhotos;
    },
    updateTours(newTours) {
      this.localSearchCriteria.tours = newTours;
    }
  },
  data() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.innerWidth < 768;

    return {
      downArrow,
      openGroups: { 
        locationInput: !isMobile,
        bedroomsBathrooms: !isMobile,
        minMaxRent: !isMobile,
        propertyStatusMedia: !isMobile,
        laundryParkingPet: !isMobile,
        availDates: !isMobile,
      },
      
      // Importing all the data sets and mappings used for API queries.
      allNeighborhoods,
      feeMapping,
      bedMapping,
      bathMapping,
      photoMapping,
      parkingMapping,
      petMapping,
      featureMapping,
      laundryMapping,
      tourMapping,

      streetName: '',
      streetNameError: null,
      zipCode: '',
      zipCodeError: null,
      maxRentError: null,
      minRentError: null,
      minSqftError: null,
      maxSqftError: null,
      
    };
  },
  mounted() {
    const isMobile = window.innerWidth < 768;
    const defaultState = !isMobile;

    this.openGroups = {
      locationInput: defaultState,
      bedroomsBathrooms: defaultState,
      minMaxRent: defaultState,
      propertyStatusMedia: defaultState,
      laundryParkingPet: defaultState,
      availDates: defaultState,
    };
  },
  watch: {
    localSearchCriteria: {
      deep: true,
      handler(newCriteria) {
        for (const key in newCriteria) {
          if (newCriteria[key] === "null") {
            this.localSearchCriteria[key] = null;
          }
        }
      }
    }
  }
}

</script>

<style scoped>
.info-group {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  border-radius: 5px;
}
.inner-group {
  width: 100%;
}
.filters {
  border-radius: 5px;
}
.filters,
.filters input {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 12px 5px 5px 16px;
  font-size: 1rem;
  border-radius: 5px;
  width: 100%;
  gap: 5px;
  align-items: center;
  font-family: inherit;
  box-sizing: border-box;
}
input::placeholder {
  color: #adadad;
}

input,
.multiselect {
  height: 48px;
}

.datepicker-container {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border-radius: 5px;
}

.value {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: 48px; /* Match the height to VueMultiselect */
  padding: 12px 5px 5px 16px; /* Padding to match VueMultiselect */
  color: black; /* Your existing color */
  font-size: 1rem; /* Match the font size to VueMultiselect */
  border: 1px solid #ccc; /* Similar border style */
  border-radius: 4px; /* Rounded corners like VueMultiselect */
  background-color: #fff; /* Background color to match */
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); /* Subtle inner shadow similar to VueMultiselect */
}
.value:focus {
  border-color: #66afe9; /* Highlight color */
  outline: 0; /* Remove default focus outline */
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6); /* Similar focus shadow */
}
.error-message {
  color: red;
  font-size: 0.8rem;
  margin: 0.2rem 0;
}

.multi-container {
  width: 100%;
}
.multiselect {
  width: 100%;
}

.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(40, 40, 40); /* Neumorphic background */
  padding: 2rem;
}

.search-btn {
  max-width: 250px;
  min-width: 100px;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  margin: 0.2rem;
  border-radius: 15px;
  background: #afc6d2;
  box-shadow: 5px 5px 10px rgb(31, 31, 31),
              -5px -5px 10px rgb(61, 61, 61);
  font-size: 1rem;
  color: #252525;
  cursor: pointer;
  transition: all 0.2s ease;
}
.search-btn:hover {
  background: #788792;
  box-shadow: 2px 2px 5px rgb(31, 31, 31),
              -2px -2px 5px rgb(61, 61, 61);
}
.reset-btn {
  max-width: 250px;
  min-width: 100px;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  margin: 0.2rem;
  border-radius: 15px;
  background: #afc6d2;
  box-shadow: 5px 5px 10px rgb(31, 31, 31),
              -5px -5px 10px rgb(61, 61, 61);
  font-size: 1rem;
  color: #252525;
  cursor: pointer;
  transition: all 0.2s ease;
}
.reset-btn:hover {
  background: #788792;
  box-shadow: 2px 2px 5px rgb(31, 31, 31),
              -2px -2px 5px rgb(61, 61, 61);
}

.toggle-btn {
  max-width: 250px;
  min-width: 100px;
  border: none;
  outline: none;
  padding: 0.8rem 1rem;
  margin: 0.2rem;
  border-radius: 15px;
  background: #696969;
  box-shadow: 5px 5px 10px rgb(31, 31, 31),
              -5px -5px 10px rgb(61, 61, 61);
  font-size: 1rem;
  color: #ebebeb;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 275px;
}
.toggle-btn::after {
  content: '';
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 5px solid #ebebeb; /* Triangle pointing downwards */
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: none;
  transition: transform 0.3s ease;
}
/* When group is open, rotate the caret to point upwards */
.toggle-btn[aria-expanded="true"]::after {
  transform: rotate(180deg);
}
.toggle-btn::hover {
  background: #93ca88;
  box-shadow: 5px 5px 10px rgb(31, 31, 31),
              -5px -5px 10px rgb(61, 61, 61);

}

:deep(.multiselect__tags) {
  font-size: 1rem;
  padding: 12px 5px 5px 16px;
  max-height: 48px;
  overflow-y: scroll;
}
:deep(.multiselect__single) {
  height: 45px; /* Ensure the single select height matches your inputs */
  padding: 0.5rem 1rem; /* Adjust padding to match */
}
:deep(.multiselect__option--highlight) {
  background: #afc6d2;
}
:deep(.multiselect__option--highlight::after) {
  background: #afc6d2;
}
:deep(.multiselect__tag) {
  background: #afc6d2;
  color: black;
}

@media only screen and (min-width: 768px) {
  .toggle-btn {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .filters input,
  :deep(.multiselect__placeholder) {
    font-size: 0.7rem
  }

  :deep(.dp__input) {
    font-size: 0.7rem;
  }

  .filters input {
    width: 100%;
    box-sizing: border-box;
  }

  :deep(.datepicker) {
    box-sizing: border-box;
  }

  :deep(.multiselect__content),
  :deep(.multiselect__tags),
  :deep(input.multiselect__input) {
    font-size: 0.8rem;
  }
  :deep(.multiselect__tags) {
    font-size: 0.6rem;
    padding: 14px 8px 8px 14px;
  }

  .info-group {
    margin: 0;
    width: 150px;
  }
  .filters {
    margin: 10px;
    width: 100%;
  }

  .search-btn {
    font-size: 0.8rem;
  }
}
</style>